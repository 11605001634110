<template>
  <v-row class="ma-0 pa-0" :style="`height: ${$vuetify.breakpoint.height - ($vuetify.breakpoint.height / 12)}px`">
    <v-col cols="12" md="6" class="fill-height d-none d-md-flex justify-center align-center pa-0 elevation-6 secondary">
      <v-img :max-width="$vuetify.breakpoint.lgAndDown ? 200 : 400" src="@/assets/logo.png"></v-img>
    </v-col>
    <v-col cols="12" md="6" class="fill-height d-flex justify-center align-center">
      <validation-observer ref="observer" v-slot="{ passes }" style="width: 80%">
        <v-form @submit.prevent="passes( submit )">
          <v-card flat tile>
            <v-card-title class="text-h4 font-weight-bold">
              <v-img src="@/assets/logo.png" alt="Starboxx Logo" max-width="200"></v-img>
              <span class="primary--text ml-2">: Login Form</span>
            </v-card-title>
            <v-card-subtitle class="caption mt-4">Login to admin panel</v-card-subtitle>
            <v-card-text>
              <validation-provider rules="required" name="Login" v-slot="{ errors }">
                <v-text-field class="my-6 rounded-0" v-model="form.username" type="email" :error-messages="errors"
                              required
                              placeholder="Please, enter your e-mail">
                  <template v-slot:label>
                    <div class="d-flex align-center">
                      <span class="mt-1">*</span>
                      <span>Login</span>
                    </div>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider rules="required" name="Password" v-slot="{ errors }">
                <v-text-field class="my-6 rounded-0" v-model="form.password" type="password" :error-messages="errors"
                              placeholder="Please, enter your password"
                              >
                  <template v-slot:label>
                    <div class="d-flex align-center">
                      <span class="mt-1">*</span>
                      <span>Password</span>
                    </div>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn type="submit"
                     dark
                     min-width="30%" color="primary" tile>login</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    submit () {
      this.$http.post(`${process.env.VUE_APP_OAUTH_URL}/token`, this.form)
    }
  }
}
</script>

<style scoped>

</style>
